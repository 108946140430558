

export interface RawLine {
    seq: string,
    id: string,
    desc: string,
    start: string | Date,
    end: string | Date,
    progress: ProgressState,
    actual: number,
    estimated: number,
    bygg: string,
    WBS: string,
    lane: number,
    lvl1: string,
    lvl2: number,
    isLvl2: boolean, // om aktuelle line representerer leveranse
    u: number,
}
export type RawLineDict = { [key: string]: RawLine }



export interface BaseElement {
    id: string, //TODO depr?
    desc: string,
    start: string,
    end: string,
    progress: ProgressState,
    actual: number,
    estimated: number,
    bygg: string,
    u: number,
    clss: ElementClass,
    alt?: string,
    lane?: number,
    eie?: Eierskifte,
    lvl1: string,
    lvl2?: Lvl2,
}

export type BaseElementDict = { [key: string]: BaseElement }
export type GroupIndex = { [key: string]: string[] }
export type MasterIndex = {
    children: GroupIndex,
    inbounds: GroupIndex, 
    clss: GroupIndex,
    filters: GroupIndex,
    eierskifte: GroupIndex,
    bygg: GroupIndex,
    byggRelated: GroupIndex,
    linkTypes: { [key: string]: string } // For all given relations, lookup-table for link type
    intermediary: GroupIndex, // For a given node (U6 eks.), yield all nodes that act as an intermediary to a relation to an IST
}
export const defaultMasterIndex: MasterIndex = {
    children: {}, inbounds: {}, clss: {}, bygg: {}, linkTypes: {}, intermediary: {},
    byggRelated: {},
    eierskifte: {
        1: [],
        2: [],
        3: [],
    },
    filters: {
        all: [],
        satPlan: [],
        specIdsUnmatched: [],
        istPlanRelated: [],
        byggspesifikke: [],
    }
}
export type XIndex = { [key: string]: { start: number, end: number } }
export type YIndex = { [key: string]: { top: number, bottom?: number } }
export type Dependency = { from: string, to: string, type: string }


export const elementClass = [
    'ist', 'kib', 'eierskifte', 'leveranse', 'generic', 'vit',
    'u7c',
    'test-do',
    'st-sit-plan',
    'st-sit-do',
    'st-sit-done',
    'sat-plan',
    'sat-do',
    'sat-done',
    'ld-ros',
    'bygg',
] as const

export const views = ['standard', 'extended', 'satplan', 'satplan-chron', 'istplan', 'byggplan'] as const

export const lvl1 = [
    '7501',
    '7502',
    '7503',
    '7504',
    '7505',
    '7506',
    '7507',
    '7509',
    '7510',
    '7511',
    '7513',
    '7514',
    '7516',
    '7591',
] as const

export const lvl2 = [
    'L01',
    'L02',
    'L03',
    'L04',
    'L05',
    'L06',
    'L07',
    'L08',
    'L09',
    'L10',
    'L11',
    'L12',
    'L13',
    'L14',
    'L15',
    'L16',
    'L17',
    'L18',
    'L19',
    'L20',
] as const

export const eierskifter = [1, 2, 3] as const
export type Eierskifte = typeof eierskifter[number]


export const lookAheads = [0, 4, 6] as const

export type ElementClass = typeof elementClass[number]
export type Lvl1 = typeof lvl1[number]
export type Lvl2 = typeof lvl2[number]
export type LookAhead = typeof lookAheads[number]

export type SpecLine = { //TODO: Depr.
    clss: ElementClass,
    alt?: string,
    lane?: number,
    lvl1?: string,
    lvl2?: Lvl2,
    eie?: Eierskifte,
}

/**
 * lvl1 & lvl2 & leveranse: er selve leveransen
 * lvl1 & lvl2 & noe annet: er element i leveransen
 * lvl1 & leveranse: ugyldig
 * lvl1 & noe annet: er element på grunnivå av lvl1.
 */

export type SpecLineDict = { [key: string]: SpecLine }

// export type CombinedElement = BaseElement & SpecLine
// export type CombinedElementDict = { [key: string]: CombinedElement }
export type View = typeof views[number]
export type Modal = '' | 'grid' | 'inspec' | 'unmatched' | 'indep'
export type ProgressState = 'lagging' | 'late' | 'done' | 'ahead' | 'unknown'

export interface ByggStruktur {
    id: string,
    code: string,
    name: string,
    color: string,
    lane: number,
}

export const byggstruktur = [
    {
        id: "121",
        code: "A",
        name: "Behandlingsbygg A",
        color: "#CF884C",
        lane: 0,
    },
    {
        id: "122",
        code: "B",
        name: "Behandlingsbygg B",
        color: "#CF884C",
        lane: 1,
    },
    {
        id: "161",
        code: "A",
        name: "Sengebygg A",
        color: "#EBCC60",
        lane: 2,
    },
    {
        id: "162",
        code: "B",
        name: "Sengebygg B",
        color: "#EBCC60",
        lane: 3,
    },
    {
        id: "140",
        code: "C",
        name: "Servicebygg C",
        color: "#BD7369",
        lane: 4,
    },
    {
        id: "152",
        code: "D",
        name: "Poliklinikk D",
        color: "#7287A9",
        lane: 5,
    },
    {
        id: "151",
        code: "E",
        name: "Poliklinikk E",
        color: "#7287A9",
        lane: 6,
    },
    {
        id: "130",
        code: "PHR",
        name: "PHR-byggene",
        color: "#698E85",
        lane: 7,
    },
    {
        id: "131",
        code: "F",
        name: "Bygg for psykisk helse og rus F",
        color: "#698E85",
        lane: 8,
    },
    {
        id: "132",
        code: "G",
        name: "Bygg for psykisk helse og rus G",
        color: "#698E85",
        lane: 9,
    },
    {
        id: "133",
        code: "H",
        name: "Bygg for psykisk helse og rus H",
        color: "#698E85",
        lane: 10,
    },
    {
        id: "110",
        code: "K",
        name: "Adkomstbygg K",
        color: "#BD7369",
        lane: 11,
    },
    {
        id: "000",
        code: "X",
        name: "Generelt bygguavhengig",
        color: "gray.50",
        lane: 12,
    },
    {
        id: "100",
        code: "Felles",
        name: "Kjeller/fellessystemer",
        color: "gray.400",
        lane: 13,
    }
]

// conversion table from planweb WBS to gjennomføringsfase for gitt tilpasningsprosjekt.
// Leveranser utgjør neste nivå, aktiviteter under det igjen.
export const planwebWBSTo75xx: any = {
    "3.7.2.2": "7501",
    "3.7.3.2": "7502",
    "3.7.4.3": "7503",
    "3.7.5.2": "7504",
    "3.7.6.3": "7505",
    "3.7.7.3": "7506",
    "3.7.8.3": "7507",
    "3.7.9.2": "7509",
    "3.7.10.2": "7510",
    "3.7.11.3": "7513",
    "3.7.12.2": "7514",
    "3.7.13.3": "7516",
    "3.7.14.2": "7591"
}

export const u7cDict: { [key: string]: { navn: string}} = {
    '7501': { navn: "EPJ" },
    '7502': { navn: "Kurve" },
    '7503': { navn: "Helselogistikk" },
    '7504': { navn: "Lab" },
    '7505': { navn: "Stråleterapi" },
    '7506': { navn: "Sporingssystem for sterilsentral" },
    '7507': { navn: "Bildediagnostikk" },
    '7509': { navn: "Med. kreftbehandling" },
    '7510': { navn: "Matforsyning" },
    '7511': { navn: "BIM/FDV" },
    '7513': { navn: "Multimedia" },
    '7514': { navn: "Sporing og lokalisering" },
    '7516': { navn: "Mobil arbeidsflate" },
    '7591': { navn: "Øvrige systemtilpasninger" },
}

export const u7c: string[] = Object.values(planwebWBSTo75xx)